<template>
  <el-drawer
    v-model="isShowFilterDrawer"
    :show-close="false"
    modal-class="elv-account-balance-verification-task-overlay"
    class="elv-account-balance-verification-task-drawer-filter"
    :before-close="onCheckDrawerStatus"
    :size="360"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-balance-verification-task-drawer-filter-header__title">
        {{ t('common.filter') }}
      </h4>
      <div class="elv-account-balance-verification-task-drawer-filter-header-operating">
        <div class="elv-account-balance-verification-task-drawer-filter-header__clear">
          <SvgIcon
            name="reset"
            width="14"
            height="14"
            class="elv-account-balance-verification-task-drawer-filter-header__close"
          />
          <span @click="onClearData('')">{{ t('button.clear') }}</span>
        </div>
        <SvgIcon
          name="close"
          width="20"
          height="20"
          class="elv-account-balance-verification-task-drawer-filter-header__close"
          @click="onCheckDrawerStatus"
        />
      </div>
    </template>
    <el-scrollbar v-loading="loading" wrap-class="elv-verification-task-drawer-filter-wrap">
      <div class="elv-verification-task-drawer-filter-item">
        <div class="elv-verification-task-drawer-filter-item-title">
          <p>{{ t('common.account') }}</p>
          <span v-show="filterData?.entityAccountId?.length" @click="onClearData('account')">{{
            t('button.clear')
          }}</span>
        </div>
        <ElvSelect
          v-model="filterData.entityAccountId"
          width="312px"
          multiple
          clearable
          filterable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          :placeholder="placeholderText"
          :class="{
            'is-unEmpty': filterData?.entityAccountId?.length
          }"
          :options="accountNormalOptions"
          :collapse-list="[]"
        />
      </div>

      <div class="elv-verification-task-drawer-filter-item">
        <div class="elv-verification-task-drawer-filter-item-title">
          <p>{{ t('common.currency') }}</p>
          <span v-show="filterData?.currency?.length" @click="onClearData('currency')">{{ t('button.clear') }}</span>
        </div>
        <el-select
          v-model="filterData.currency"
          multiple
          clearable
          filterable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          :class="{ 'is-unEmpty': filterData?.currency?.length }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-verification-task-filter-dropdown"
          :popper-append-to-body="false"
          style="width: 100%"
          @change="onCheckedCurrencyChange"
        >
          <template #label="{ label, value }">
            <img :src="currencyLogo(value).value" :alt="label" />
            <span>{{ label }}</span>
          </template>
          <template v-for="(item, index) in currencyOptions" :key="index">
            <el-checkbox
              v-model="currencySelectData[`checkAll${capitalize(item?.type)}`]"
              class="elv-select-transaction-filter-currency__all"
              :indeterminate="currencySelectData[`isIndeterminate${capitalize(item?.type)}`]"
              @change="onCheckCurrencyClassifyAllChange(capitalize(item?.type), index, $event as boolean)"
              >{{ transformI18n(item?.label) }}</el-checkbox
            >
            <el-checkbox-group v-model="filterData.currency">
              <el-option v-for="i in item.list" :key="i.value" :label="i?.label" :value="i.value">
                <el-checkbox style="pointer-events: none" :value="i?.value"
                  ><img :src="i?.logo" :alt="i.label" />{{ i?.label }}</el-checkbox
                >
              </el-option>
            </el-checkbox-group>
          </template>
        </el-select>
      </div>

      <div class="elv-verification-task-drawer-filter-item">
        <div class="elv-verification-task-drawer-filter-item-title">
          <p>{{ t('common.startDateRange') }}</p>
          <span v-show="filterData.startDatetime.length" @click="onClearData('startDateTime')">{{
            t('button.clear')
          }}</span>
        </div>
        <div
          class="elv-select-transaction-filter-date-picker"
          :class="{ 'is-unEmpty': filterData.startDatetime.length }"
        >
          <DatePicker
            ref="datePickerRef"
            v-model:value="filterData.startDatetime"
            :type="'range'"
            :picker="'date'"
            :allowClear="false"
            @change="onStartRangeChange"
          />
        </div>
      </div>

      <div class="elv-verification-task-drawer-filter-item">
        <div class="elv-verification-task-drawer-filter-item-title">
          <p>{{ t('common.endDateRange') }}</p>
          <span v-show="filterData.endDatetime.length" @click="onClearData('endDateTime')">{{
            t('button.clear')
          }}</span>
        </div>
        <div class="elv-select-transaction-filter-date-picker" :class="{ 'is-unEmpty': filterData.endDatetime.length }">
          <DatePicker
            ref="datePickerRef"
            v-model:value="filterData.endDatetime"
            :type="'range'"
            :picker="'date'"
            :allowClear="false"
            @change="onEndRangeChange"
          />
        </div>
      </div>

      <div class="elv-verification-task-drawer-filter-item">
        <div class="elv-verification-task-drawer-filter-item-title">
          <p>{{ t('reconciliation.reason') }}</p>
          <span v-show="filterData.treasuryBalanceVerifyReasonId.length" @click="onClearData('reason')">{{
            t('button.clear')
          }}</span>
        </div>
        <div :class="{ 'is-unEmpty': filterData.treasuryBalanceVerifyReasonId.length }">
          <ElvSelect
            v-model="filterData.treasuryBalanceVerifyReasonId"
            width="312px"
            multiple
            clearable
            filterable
            collapse-tags
            collapse-tags-tooltip
            :max-collapse-tags="2"
            :placeholder="placeholderText"
            :class="{
              'is-unEmpty': filterData?.treasuryBalanceVerifyReasonId?.length
            }"
            :options="reasonOptions"
            :collapse-list="[]"
          />
        </div>
      </div>
    </el-scrollbar>
    <template #footer>
      <elv-button
        :loading="saveLoading"
        type="primary"
        round
        width="204"
        height="36"
        class="elv-account-balance-verification-task-drawer-filter-footer__save"
        @click="onSave"
      >
        {{ t('button.apply') }}
      </elv-button>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { $t, transformI18n } from '@/i18n/index'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useAccountStore } from '@/stores/modules/accounts'
import { useTransactionStore } from '@/stores/modules/transactions'
import { TreasuryBalanceVerificationTaskStatusType } from '#/AccountsTypes'
import { find, map, groupBy, isEmpty, difference, capitalize } from 'lodash-es'
import DatePicker from '@/pages/Financials/Project/Transactions/components/DatePicker.vue'

const emit = defineEmits(['onFilterData'])

const props = defineProps({
  tabType: {
    type: String,
    default: TreasuryBalanceVerificationTaskStatusType.PENDING
  }
})
const { t } = useI18n()
const route = useRoute()
const accountStore = useAccountStore()
const transactionStore = useTransactionStore()
const datePickerRef = useTemplateRef('datePickerRef')
const { transactionCurrencyList } = storeToRefs(transactionStore)
const { treasuryBalanceVerifyReasonList, treasuryBalanceVerifyTaskFilterList } = storeToRefs(accountStore)

const isShowFilterDrawer = ref(false)
const saveLoading = ref(false)
const loading = ref(false)

const filterData = ref<{
  entityAccountId: string[]
  currency: string[]
  startDatetime: string[]
  endDatetime: string[]
  treasuryBalanceVerifyReasonId: string[]
}>({
  entityAccountId: [],
  currency: [],
  startDatetime: [],
  endDatetime: [],
  treasuryBalanceVerifyReasonId: []
})

const currencySelectData: any = ref({
  checkAllCrypto: false,
  checkAllFiat: false,
  checkAllPair: false,
  isIndeterminateCrypto: false,
  isIndeterminateFiat: false,
  isIndeterminatePair: false,
  lastTimeData: []
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const placeholderText = computed(() => {
  return `${t('common.select')}...`
})

const currencyLogo: any = useComputedHook((value: string) => {
  const symbol = value.split('__')?.[1]
  return find(transactionCurrencyList.value, { symbol })?.logo ?? ''
})

const currencyOptions = computed(() => {
  const currencyGroupList = groupBy(transactionCurrencyList.value, 'type')
  return map(currencyGroupList, (items, type) => {
    const label = type === 'CRYPTO' ? $t('title.allCrypto') : $t('title.allFiat')
    const list = items.map(({ symbol, showSymbol, logo }) => ({
      label: showSymbol,
      value: `${type === 'CRYPTO' ? 'Crypto' : 'fiat'}__${symbol}`,
      logo
    }))
    return { label, type: type === 'CRYPTO' ? 'Crypto' : 'fiat', list }
  })
})

const accountNormalOptions = computed(() => {
  const accountList = accountStore.accountList.filter((item: any) => {
    return item.status === 'NORMAL'
  })
  return accountList.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform?.logo,
      isCheck: false
    }
  })
})

const reasonOptions = computed(() => {
  return treasuryBalanceVerifyReasonList.value.map((item) => {
    return {
      id: item.treasuryBalanceVerifyReasonId,
      value: item.treasuryBalanceVerifyReasonId,
      label: item.name,
      isCheck: false
    }
  })
})

const verificationFilterData = computed(() => {
  const filterSaveData = treasuryBalanceVerifyTaskFilterList.value.find((item) => {
    return item.type === props.tabType && item.entityId === entityId.value
  })
  if (filterSaveData) return filterSaveData?.data
  return {
    entityAccountId: [],
    currency: [],
    startDatetime: [],
    endDatetime: [],
    treasuryBalanceVerifyReasonId: []
  }
})

const onCheckCurrencyClassifyAllChange = (type: any, index: number, val: boolean) => {
  currencySelectData.value[`isIndeterminate${type}`] = false
  if (val) {
    filterData.value.currency.push(...currencyOptions.value[index].list.map((item: any) => item.value))
    filterData.value.currency = Array.from(new Set(filterData.value.currency))
  } else {
    filterData.value.currency = filterData.value.currency.filter(
      (item: any) => !currencyOptions.value[index].list.map((i: any) => i.value).includes(item)
    )
  }
  currencySelectData.value.lastTimeData = filterData.value.currency
}

const onCheckedCurrencyChange = (value: any[]) => {
  const differenceType =
    difference(value, currencySelectData.value.lastTimeData)?.[0] ??
    difference(currencySelectData.value.lastTimeData, value)?.[0] ??
    ''
  const type = differenceType ? capitalize(differenceType.split('__')[0]) : ''
  currencySelectData.value.lastTimeData = value
  const typeList = currencyOptions.value.find((item: any) => capitalize(item.type) === type)?.list
  if (typeList) {
    currencySelectData.value[`isIndeterminate${type}`] = !!(value.length > 0 && value.length < typeList.length)
    currencySelectData.value[`checkAll${type}`] = value.length === typeList.length
  } else {
    currencySelectData.value[`isIndeterminate${type}`] = false
    currencySelectData.value[`checkAll${type}`] = false
  }
}

const onClearData = (type: string) => {
  switch (type) {
    case 'account':
      filterData.value.entityAccountId = []
      break
    case 'currency':
      filterData.value.currency = []
      break
    case 'startDateTime':
      filterData.value.startDatetime = []
      break
    case 'endDateTime':
      filterData.value.endDatetime = []
      break
    case 'reason':
      filterData.value.treasuryBalanceVerifyReasonId = []
      break
    default:
      filterData.value = {
        entityAccountId: [],
        currency: [],
        startDatetime: [],
        endDatetime: [],
        treasuryBalanceVerifyReasonId: []
      }
      break
  }
}

const onStartRangeChange = (value: any) => {
  filterData.value.startDatetime = value !== null ? value : []
}

const onEndRangeChange = (value: any) => {
  filterData.value.endDatetime = value !== null ? value : []
}

const onCheckDrawerStatus = () => {
  isShowFilterDrawer.value = !isShowFilterDrawer.value
}

defineExpose({ onCheckDrawerStatus })

const onSave = async () => {
  try {
    saveLoading.value = true
    const newFilterData = JSON.parse(JSON.stringify(filterData.value))
    Object.keys(newFilterData).forEach((item: any) => {
      if (Array.isArray(newFilterData[item]) && !newFilterData[item].length) {
        delete newFilterData[item]
      } else if (!newFilterData[item]) {
        delete newFilterData[item]
      }
    })
    ElMessage.success(t('message.success'))
    accountStore.editTreasuryBalanceVerifyTaskFilterList(entityId.value, props.tabType, newFilterData)
    emit('onFilterData')
    onCheckDrawerStatus()
  } catch (error: any) {
    ElMessage.error(error.message)
    console.error(error)
  } finally {
    saveLoading.value = false
  }
}

const initFilterView = async () => {
  try {
    loading.value = true
    nextTick(() => {
      if (!isEmpty(verificationFilterData.value)) {
        filterData.value = {
          entityAccountId: verificationFilterData.value?.entityAccountId ?? [],
          currency: verificationFilterData.value?.currency ?? [],
          startDatetime: verificationFilterData.value?.startDatetime ?? [],
          endDatetime: verificationFilterData.value?.endDatetime ?? [],
          treasuryBalanceVerifyReasonId: verificationFilterData.value?.treasuryBalanceVerifyReasonId ?? []
        }
        if (verificationFilterData.value?.currency?.length) {
          currencySelectData.value = {
            checkAllCrypto: false,
            checkAllFiat: false,
            checkAllPair: false,
            isIndeterminateCrypto: false,
            isIndeterminateFiat: false,
            isIndeterminatePair: false,
            lastTimeData: verificationFilterData.value?.currency
          }
          verificationFilterData.value?.currency.forEach((item: any) => {
            const type = capitalize(item.split('__')[0])
            const typeDataLength =
              verificationFilterData.value?.currency?.filter((i: any) => capitalize(i.split('__')[0]) === type)
                .length ?? 0
            currencySelectData.value[`checkAll${type}`] =
              currencyOptions.value.find((i: any) => capitalize(i.type) === type)?.list.length === typeDataLength
            currencySelectData.value[`isIndeterminate${type}`] = !!(
              (typeDataLength > 0 &&
                (verificationFilterData.value?.currency ?? []).filter((i: any) => capitalize(i.split('__')[0]) === type)
                  .length < (currencyOptions.value.find((i: any) => capitalize(i.type) === type)?.list.length ?? 0)) ||
              false
            )
          })
        }
      } else {
        onClearData('')
      }
    })
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

watch(
  () => isShowFilterDrawer.value,
  (status) => {
    if (status) {
      initFilterView()
    } else {
      onClearData('')
    }
  }
)
</script>

<style lang="scss" scoped>
.elv-account-balance-verification-task-drawer-filter {
  .elv-account-balance-verification-task-drawer-filter-header__title {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #1e2024;
    margin: 0;
  }

  .elv-account-balance-verification-task-drawer-filter-header-operating {
    display: flex;
    align-items: center;
  }

  .elv-account-balance-verification-task-drawer-filter-header__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;

    span {
      display: block;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 6px;
      padding-right: 8px;
      border-right: 1px solid #dde1e6;
    }
  }

  .elv-account-balance-verification-task-drawer-filter-header__close {
    padding: 4px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-account-balance-verification-task-drawer-filter {
  .el-drawer__header {
    padding: 10px 0px;
    margin: 0 24px;
    border-bottom: 1px solid #d0d4d9;
    height: 48px;
    box-sizing: border-box;
    display: flex;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .elv-verification-task-drawer-filter-wrap {
    padding: 0px 24px 20px !important;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .elv-verification-task-drawer-filter-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .elv-verification-task-drawer-filter-item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Plus Jakarta Sans';
      margin-bottom: 4px;
      height: 24px;

      p {
        color: #1e2024;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      span {
        color: #5e85eb;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }
    }

    .elv-select-transaction-filter-date-picker {
      border: 1px solid #dde1e6;
      box-sizing: border-box;
      height: 44px;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

      &.is-unEmpty {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }
  }
}

.el-select.is-unEmpty {
  &.is-not-underlined {
    .el-select__wrapper.is-hovering {
      border-bottom: 0px !important;
    }
  }

  .el-select__wrapper {
    border-color: #5e85eb;
    background-color: #f7f9fe;
    min-height: 44px;
    height: fit-content;

    &.is-hovering {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
    }

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-tag__content {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        .el-select__tags-text {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        color: #aaafb6;
        margin-left: 2px;

        &:hover {
          color: #fff;
          background-color: #909399;
        }
      }
    }
  }

  .el-select__selected-item {
    display: flex;

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-select__tags-text {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}

.el-select.is-select-value {
  .el-select__wrapper {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 0px;
  }
}

.el-select.is-select-value-top {
  .el-select__wrapper {
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }
}

.elv-verification-task-filter-dropdown {
  .elv-select-transaction-filter-type__all,
  .elv-select-transaction-filter-currency__all {
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;
    margin-right: 0px;
    border-top: 1px solid #edf0f3;
    border-bottom: 1px solid #edf0f3;

    &:first-of-type {
      border-top: 0px;
    }

    .el-checkbox__label {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .el-checkbox-group {
    .el-checkbox {
      .el-checkbox__label {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }

  &.journal-type {
    padding: 0px;

    .el-select-dropdown__empty {
      padding: 0px;
    }
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    padding-left: 8px;

    &.selected::after,
    &.is-selected::after {
      width: 0;
      height: 0;
    }
  }
}
</style>
