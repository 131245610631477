<template>
  <div class="elv-account-balance-verification-task-table-container">
    <div class="verification-task-table-container-item-container">
      <div class="verification-task-table-container-item">
        <CustomTable
          border
          stripe
          :tableData="tableData"
          :tableHeader="currentTableHeader"
          :isShowPagination="false"
          :tableLoading="props.tableLoading"
          :empty-text="t('common.noData')"
          class="elv-account-balance-verification-task-list-table"
          @row-click="onCellClicked"
        >
          <template #index="{ index }">
            <span class="elv-verification-task-table-container-item-index">{{ getGlobalIndex(index).value }}</span>
          </template>
          <template #taskId="{ data }">
            <TextCell
              :text="data.treasuryBalancePeriodNo || '-'"
              :weight="400"
              size="13px"
              fontFamily="Plus Jakarta Sans"
            />
          </template>
          <template #account="{ data }">
            <IconWithTitleCell
              class="elv-verification-task-table-container-item-normal"
              :title="data.entityAccount?.name || ''"
              :icon="data.entityAccount?.platform?.logo || ''"
              titleFontFamily="Plus Jakarta Sans"
              :titleWeight="500"
              titleSize="13px"
            />
          </template>
          <template #currency="{ data }">
            <IconWithTitleCell
              class="elv-verification-task-table-container-item-normal"
              :title="data.underlyingCurrency?.showSymbol || ''"
              :icon="data.underlyingCurrency?.logo || ''"
              titleFontFamily="Barlow"
              :titleWeight="400"
              titleSize="13px"
            />
          </template>
          <template #dateRange="{ data }">
            <div class="elv-verification-task-table-container-item-date-range">
              <VerticalTextCell
                :upText="dateTimeFormat(data, 'start').value?.date"
                :downText="dateTimeFormat(data, 'start').value?.time"
                upFontFamily="Barlow"
                upFontSize="13px"
                :upWeight="400"
                downFontFamily="Barlow"
                downFontSize="11px"
                :downWeight="400"
                align="left"
              ></VerticalTextCell>
              <span class="elv-verification-task-table-container-item-date-range-split">-</span>
              <VerticalTextCell
                :upText="dateTimeFormat(data, 'end').value?.date"
                :downText="dateTimeFormat(data, 'end').value?.time"
                upFontFamily="Barlow"
                upFontSize="13px"
                :upWeight="400"
                downFontFamily="Barlow"
                downFontSize="11px"
                :downWeight="400"
                align="left"
              ></VerticalTextCell>
            </div>
          </template>
          <template #line1> </template>
          <template #startingSnapshot="{ data }">
            <TextCell
              class="elv-verification-task-table-container-item-number"
              :text="balanceFormat(data.beginBalance, data).value.balance"
              :titleDesc="balanceFormat(data.beginBalance, data).desc"
              fontFamily="Barlow"
              :weight="500"
              size="13px"
              align="right"
            />
          </template>
          <template #addSymbol>
            <TextCell
              class="elv-verification-task-table-container-item-symbol"
              text="+"
              fontFamily="Barlow"
              :weight="500"
              size="13px"
            />
          </template>
          <template #inflow="{ data }">
            <TextCell
              class="elv-verification-task-table-container-item-number"
              :text="balanceFormat(data.transactionInflowAmount).value.balance"
              :titleDesc="balanceFormat(data.transactionInflowAmount).desc"
              fontFamily="Barlow"
              :weight="500"
              size="13px"
              align="right"
            />
          </template>
          <template #deleteSymbol>
            <TextCell
              class="elv-verification-task-table-container-item-symbol"
              text="-"
              fontFamily="Barlow"
              :weight="500"
              size="13px"
            />
          </template>
          <template #outflow="{ data }">
            <TextCell
              class="elv-verification-task-table-container-item-number"
              :text="balanceFormat(data.transactionOutflowAmount).value.balance"
              :titleDesc="balanceFormat(data.transactionOutflowAmount).desc"
              fontFamily="Barlow"
              :weight="500"
              size="13px"
              align="right"
            />
          </template>
          <template #equalSymbol>
            <TextCell
              class="elv-verification-task-table-container-item-symbol"
              text="="
              fontFamily="Barlow"
              :weight="500"
              size="13px"
            />
          </template>
          <template #endingBalance="{ data }">
            <TextCell
              class="elv-verification-task-table-container-item-number"
              :text="balanceFormat(data.calculateEndBalance).value.balance"
              fontFamily="Barlow"
              :weight="500"
              size="13px"
              align="right"
            />
          </template>
          <template #line2> </template>
          <template #endingSnapshot="{ data }">
            <TextCell
              class="elv-verification-task-table-container-item-number"
              :text="balanceFormat(data.endBalance).value.balance"
              fontFamily="Barlow"
              :weight="500"
              size="13px"
              align="right"
            />
          </template>
          <template #difference="{ data }">
            <VerticalTextCell
              :class="{
                'elv-verification-task-table-container-item-difference-waring': +data.difference !== 0
              }"
              :upText="differenceFormat(data).value?.amount"
              :downText="differenceFormat(data).value?.amountFC"
              :upDesc="differenceFormat(data).value?.amountDesc"
              :downDesc="differenceFormat(data).value?.amountFCDesc"
              upFontFamily="Barlow"
              upFontSize="13px"
              :upWeight="500"
              downFontFamily="Barlow"
              downFontSize="11px"
              :downWeight="400"
            ></VerticalTextCell>
          </template>
          <template #action="{ data }">
            <div class="elv-verification-task-table-container-item-action" @click="onExplain($event, data)">
              <SvgIcon name="sources-edit" width="12" height="12" :fill="`#1343BF`" />
              {{ t('reconciliation.explain') }}
            </div>
          </template>
          <template #reason="{ data }">
            <div
              v-if="data?.treasuryBalanceVerifyReason && data?.difference !== '0'"
              class="elv-verification-task-table-container-item-reason"
            >
              <span>{{ t('reconciliation.explained') }}</span>
              <span>{{ data.treasuryBalanceVerifyReason?.name || '-' }}</span>
            </div>
          </template>
          <template #detailTag="{ data }">
            <div class="elv-verification-task-table-container-item-operating">
              <Popover
                trigger="hover"
                placement="bottomLeft"
                overlay-class-name="elv-verification-task-table-container-item-operating-popover"
                :offset="2"
              >
                <SvgIcon width="14" height="14" name="more-dian" class="elv-transactions-table-row__operating-icon" />

                <template #content>
                  <div
                    class="elv-verification-task-table-container-item-operating-content"
                    :style="{ display: 'flex', 'align-items': 'center', gap: '4px', cursor: 'pointer' }"
                    @click="onViewDetail(data)"
                  >
                    <SvgIcon name="side-panel--close" width="18" height="18" fill="#D0D4D9" />{{
                      t('button.viewDetail')
                    }}
                  </div>
                </template>
              </Popover>
            </div>
          </template>
          <template #startingSnapshotHeader>
            <el-tooltip
              effect="dark"
              popper-class="elv-account-balance-verification-task-list-table-desc-header-tip"
              :show-after="500"
            >
              <template #content>
                <p>
                  {{ t('desc.startingSnapshotTipInfo') }}
                </p>
              </template>
              <div class="elv-account-balance-verification-task-list-table-desc-header">
                <span>{{ t('project.startingSnapshot') }}</span>
                <SvgIcon name="information-tip-icon" :width="12" :height="12" />
              </div>
            </el-tooltip>
          </template>
          <template #endingBalanceHeader>
            <el-tooltip
              effect="dark"
              popper-class="elv-account-balance-verification-task-list-table-desc-header-tip"
              :show-after="500"
            >
              <template #content>
                <p>
                  {{ t('desc.endingBalanceTopInfo') }}
                </p>
              </template>
              <div class="elv-account-balance-verification-task-list-table-desc-header">
                <span>{{ t('project.endingBalance') }}</span>
                <SvgIcon name="information-tip-icon" :width="12" :height="12" />
              </div>
            </el-tooltip>
          </template>
          <template #endingSnapshotHeader>
            <el-tooltip
              effect="dark"
              popper-class="elv-account-balance-verification-task-list-table-desc-header-tip"
              :show-after="500"
            >
              <template #content>
                <p>
                  {{ t('desc.endingSnapshotTipInfo') }}
                </p>
              </template>
              <div class="elv-account-balance-verification-task-list-table-desc-header">
                <span>{{ t('project.endingSnapshot') }}</span>
                <SvgIcon name="information-tip-icon" :width="12" :height="12" />
              </div>
            </el-tooltip>
          </template>
        </CustomTable>
      </div>
    </div>
    <elv-pagination
      v-if="treasuryBalanceVerifyTaskList.filteredCount > 0"
      class="elv-component-custom-list-pagination"
      size-popper-class="elv-transactions-pagination__sizes-popper"
      :limit="props.pageParams.limit"
      :current-page="props.pageParams.page"
      :total="treasuryBalanceVerifyTaskList.filteredCount"
      @current-change="onChangeSidePage"
      @size-change="onChangeSidePageSize"
    />
    <ExplanationDialog ref="explanationSettingRef" :currentData="currentExplainData" @onResetList="onResetList" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { $t } from '@/i18n'
import { useRouter } from 'vue-router'
import { Popover } from 'ant-design-vue'
import { isEmpty, isNumber } from 'lodash-es'
import {
  TreasuryBalanceVerificationItemType,
  TreasuryBalanceVerificationDetailType,
  TreasuryBalanceVerificationTaskStatusType
} from '#/AccountsTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useAccountStore } from '@/stores/modules/accounts'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import ExplanationDialog from '../../components/ExplanationDialog.vue'
import { fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'
import CustomTable from '@/pages/Financials/Project/components/CustomTable.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import IconWithTitleCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'

const props = defineProps<{
  tabType: string
  pageParams: {
    limit: number
    page: number
  }
  tableLoading: boolean
}>()

const emits = defineEmits(['onResetList', 'onChangeSidePage', 'onChangeSidePageSize'])

const { t } = useI18n()
const router = useRouter()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const { entityDetail } = storeToRefs(entityStore)
const { treasuryBalanceVerifyTaskList } = storeToRefs(accountStore)
const explanationSettingRef = useTemplateRef('explanationSettingRef')

const currentExplainData = ref({} as any)

const tableHeader: any[] = [
  {
    dataIndex: 'index',
    title: '',
    slotName: 'index',
    cellName: 'elv-verification-task-table-container-item-center',
    width: 40,
    fixed: 'left'
  },
  {
    dataIndex: 'taskId',
    title: $t('common.taskId'),
    slotName: 'taskId',
    width: 110,
    fixed: 'left'
  },
  {
    dataIndex: 'account',
    title: $t('common.account'),
    slotName: 'account',
    fixed: 'left'
  },
  {
    dataIndex: 'currency',
    title: $t('common.currency'),
    slotName: 'currency',
    width: 75,
    fixed: 'left'
  },
  {
    dataIndex: 'dateRange',
    title: $t('common.dateRange'),
    slotName: 'dateRange',
    fixed: 'left',
    min_width: 200
  },
  {
    dataIndex: 'line1',
    title: '',
    slotName: 'line1',
    cellName: 'elv-verification-task-table-container-item-blue',
    width: 6,
    fixed: 'left'
  },
  {
    dataIndex: 'startingSnapshot',
    title: $t('project.startingSnapshot'),
    slotName: 'startingSnapshot',
    width: 140,
    align: 'right',
    headerAlign: 'right',
    isCustomHeader: true,
    headerSlotName: 'startingSnapshotHeader'
  },
  {
    dataIndex: 'addSymbol',
    title: '+',
    slotName: 'addSymbol',
    cellName: 'elv-verification-task-table-container-item-center',
    width: 40
  },
  {
    dataIndex: 'inflow',
    title: $t('project.inflow'),
    slotName: 'inflow',
    width: 80,
    align: 'right',
    headerAlign: 'right'
  },
  {
    dataIndex: 'deleteSymbol',
    title: '-',
    slotName: 'deleteSymbol',
    cellName: 'elv-verification-task-table-container-item-center',
    width: 40
  },
  {
    dataIndex: 'outflow',
    title: $t('project.outflow'),
    slotName: 'outflow',
    width: 80,
    align: 'right',
    headerAlign: 'right'
  },
  {
    dataIndex: 'equalSymbol',
    title: '=',
    slotName: 'equalSymbol',
    cellName: 'elv-verification-task-table-container-item-center',
    width: 40
  },
  {
    dataIndex: 'endingBalance',
    title: $t('project.endingBalance'),
    slotName: 'endingBalance',
    width: 140,
    align: 'right',
    headerAlign: 'right',
    isCustomHeader: true,
    headerSlotName: 'endingBalanceHeader'
  },
  {
    dataIndex: 'line2',
    title: '',
    slotName: 'line2',
    cellName: 'elv-verification-task-table-container-item-blue',
    width: 6
  },
  {
    dataIndex: 'endingSnapshot',
    title: $t('project.endingSnapshot'),
    slotName: 'endingSnapshot',
    width: 140,
    align: 'right',
    headerAlign: 'right',
    isCustomHeader: true,
    headerSlotName: 'endingSnapshotHeader'
  },
  {
    dataIndex: 'difference',
    title: $t('common.difference'),
    slotName: 'difference',
    width: 140,
    align: 'right',
    headerAlign: 'right'
  },
  {
    dataIndex: 'detailTag',
    title: '',
    slotName: 'detailTag',
    fixed: 'right',
    width: 44
  }
]

const tableData = computed(() => {
  return treasuryBalanceVerifyTaskList.value.list || []
})

const currentTableHeader = computed(() => {
  const options = [...tableHeader]
  if (props.tabType === TreasuryBalanceVerificationTaskStatusType.PENDING) {
    const actionProp = {
      dataIndex: 'action',
      title: $t('common.action'),
      slotName: 'action',
      cellName: 'elv-verification-task-table-container-item-center',
      width: 100
    }
    options.splice(options.length - 1, 0, actionProp)
  } else {
    const reasonProp = {
      dataIndex: 'reason',
      title: $t('reconciliation.reason'),
      slotName: 'reason',
      width: 100
    }
    options.splice(options.length - 1, 0, reasonProp)
  }
  return options
})

const getGlobalIndex: any = useComputedHook((index: number) => {
  return (props.pageParams.page - 1) * props.pageParams.limit + index + 1
})

const dateTimeFormat: any = useComputedHook((data: TreasuryBalanceVerificationItemType, type: 'start' | 'end') => {
  const timeFormatInfo = { date: '', time: '' }
  if (type === 'start') {
    if (!data.beginDatetime) return timeFormatInfo
    timeFormatInfo.date = dayjs(data.beginDatetime)
      .tz(entityStore.entityDetail?.timezone ?? 'UTC')
      .format('YYYY/MM/DD')
    timeFormatInfo.time = dayjs(data.beginDatetime)
      .tz(entityStore.entityDetail?.timezone ?? 'UTC')
      .format('HH:mm:ss')
  } else {
    if (!data.endDatetime) return timeFormatInfo
    timeFormatInfo.date = dayjs(data.endDatetime)
      .tz(entityStore.entityDetail?.timezone ?? 'UTC')
      .format('YYYY/MM/DD')
    timeFormatInfo.time = dayjs(data.endDatetime)
      .tz(entityStore.entityDetail?.timezone ?? 'UTC')
      .format('HH:mm:ss')
  }
  return timeFormatInfo
})

const balanceFormat: any = useComputedHook((balance: any, data?: TreasuryBalanceVerificationItemType) => {
  const balanceFormatInfo = { balance: '', desc: '' }
  if (!isEmpty(data) && data?.beginDatetime) {
    const startDate = new Date(data?.beginDatetime)
    if (startDate.getFullYear() === 1970 && !data?.beginSourceId) {
      balanceFormatInfo.balance = '-'
      return balanceFormatInfo
    }
  }
  if (!balance && balance !== 0) {
    balanceFormatInfo.balance = '-'
    return balanceFormatInfo
  }
  balanceFormatInfo.balance = formatNumberToSignificantDigits(balance, 2, '', false)
  balanceFormatInfo.desc = balance
  return balanceFormatInfo
})

const differenceFormat: any = useComputedHook((data: TreasuryBalanceVerificationItemType) => {
  const differenceFormatInfo = { amount: '', amountFC: '', amountDesc: '', amountFCDesc: '' }
  if (isNumber(+data.difference)) {
    const symbolIcon = data.underlyingCurrency?.showSymbol || ''
    const differenceValue = `${formatNumberToSignificantDigits(data.difference, 2, '')}`
    differenceFormatInfo.amount = `${differenceValue}${symbolIcon}`
    differenceFormatInfo.amountDesc = `${data.difference} ${symbolIcon}`
  }
  if (isNumber(+data.differenceFC)) {
    differenceFormatInfo.amountFC = fieldValueFormat(
      +data.differenceFC,
      {
        price: true,
        symbol: `${entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `}`
      },
      'NUMBER'
    )
    differenceFormatInfo.amountFCDesc = fieldValueFormat(
      +data.differenceFC,
      {
        keepPoint: true,
        price: true,
        symbol: `${entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `}`
      },
      'NUMBER'
    )
  }
  return differenceFormatInfo
})

const onExplain = (event: any, data: TreasuryBalanceVerificationItemType) => {
  event.stopPropagation()
  currentExplainData.value = data
  explanationSettingRef.value?.onCheckExplainDialog()
}

const onViewDetail = (data: TreasuryBalanceVerificationDetailType) => {
  accountStore.treasuryBalanceVerifyTaskDetail = data
  router.push({
    name: 'entity-accounts-balance-verify-task-detail',
    params: {
      treasuryBalancePeriodId: data.treasuryBalancePeriodId
    },
    query: {
      type: props.tabType
    },
    state: {
      page: props.pageParams.page
    }
  })
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: TreasuryBalanceVerificationDetailType, column: any, e: any) => {
  onViewDetail(row)
}

const onResetList = () => {
  emits('onResetList')
}

const onChangeSidePage = (page: number) => {
  emits('onChangeSidePage', page)
}

const onChangeSidePageSize = (pageSize: number) => {
  emits('onChangeSidePageSize', pageSize)
}
</script>

<style lang="scss" scoped>
.elv-account-balance-verification-task-table-container {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  min-height: 0px;

  .verification-task-table-container-item-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 50px);
    position: relative;

    .verification-task-table-container-item {
      height: 100%;
      width: 100%;
      position: relative;

      .elv-account-balance-verification-task-list-table {
        :deep(.elv-verification-task-table-container-item-center) {
          .cell {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        :deep(.elv-verification-task-table-container-item-blue) {
          background: #eef4fb;
          border-bottom: none;
          border-right: 1px solid #ced7e0;
          border-left: 1px solid #ced7e0;
        }

        :deep(.el-table__empty-block) {
          border-right: none;
        }

        .elv-verification-task-table-container-item-index {
          font-family: 'Barlow';
          font-size: 11px;
          font-weight: 400;
          line-height: 13.2px;
          color: #aaafb6;
        }

        .elv-verification-task-table-container-item-normal {
          padding-left: 0px;
        }

        .elv-verification-task-table-container-item-date-range {
          display: flex;
          align-items: center;
          gap: 8px;

          .elv-verification-task-table-container-item-date-range-split {
            font-family: Barlow;
            font-size: 13px;
            font-weight: 400;
          }
        }

        .elv-verification-task-table-container-item-action {
          cursor: pointer;
          font-family: Plus Jakarta Sans;
          font-size: 11px;
          font-weight: 500;
          color: #1343bf;
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .elv-verification-task-table-container-item-number {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .elv-verification-task-table-container-item-reason {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-family: Barlow;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            color: #6b7177;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
              font-family: Plus Jakarta Sans;
              font-size: 11px;
              font-weight: 500;
              color: #1753eb;
              width: 68px;
              height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #eff4ff;
              border-radius: 40px;
            }
          }
        }

        .elv-transactions-table-row__operating-icon {
          cursor: pointer;
        }

        .elv-verification-task-table-container-item-difference-waring {
          :deep(.elv-base-cell-vertical-text-up) {
            color: #df2200;
          }
        }

        .elv-account-balance-verification-task-list-table-desc-header {
          display: flex;
          align-items: center;

          svg {
            margin-left: 4px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.elv-account-balance-verification-task-list-table-desc-header-tip {
  p {
    white-space: pre-wrap;
  }
}
</style>
