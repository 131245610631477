<template>
  <div class="elv-treasury-balance-verification-tasks-container">
    <header class="elv-treasury-balance-verification-tasks-header">
      <div class="elv-treasury-balance-verification-tasks-header__info">
        <h4>{{ t('title.balanceVerificationTasks') }}</h4>
        <!-- 转换为对应entity时区 -->
        <p v-if="entityStore.entityDetail?.treasuryVerifyDatetime">
          {{
            dayjs(entityStore.entityDetail?.treasuryVerifyDatetime)
              .tz(entityStore.entityDetail?.timezone ?? 'UTC')
              .format('YYYY/MM/DD HH:mm:ss')
          }}
          {{ currentEntityTimezone }}
        </p>
      </div>

      <div class="elv-treasury-balance-verification-tasks-header__operation">
        <div class="elv-treasury-balance-verification-tasks-header__button" @click="onOpenToleranceSetting">
          <SvgIcon name="project-setting" width="14" height="14" fill="#838D95" />
          <span>{{ t('valuation.tolerance') }}</span>
        </div>
        <div class="elv-treasury-balance-verification-tasks-header__line"></div>
        <div
          class="elv-treasury-balance-verification-tasks-header__button is-verify"
          :class="{ 'is-disabled': entityStore.entityDetail?.treasuryVerifyStatus === 'DOING' }"
          @click="onRefreshVerificationTask"
        >
          <img
            v-if="entityStore.entityDetail?.treasuryVerifyStatus === 'DOING'"
            src="@/assets/img/reports/sources-sync-loading.png"
            alt="loading"
          />
          <div v-loading="verifyLoading">
            <SvgIcon
              v-if="entityStore.entityDetail?.treasuryVerifyStatus !== 'DOING'"
              name="renew"
              width="14"
              height="14"
              fill="#838D95"
            />
            <span>{{
              entityStore.entityDetail?.treasuryVerifyStatus !== 'DOING'
                ? t('button.refreshVerificationTask')
                : t('button.refreshingVerificationTask')
            }}</span>
          </div>
        </div>
      </div>
    </header>

    <balanceVerificationTasksTabs
      v-model:activeTab="activeTab"
      :pendingTotal="+treasuryBalanceVerifyTaskStat?.pendingTotal"
      :passedTotal="+treasuryBalanceVerifyTaskStat?.passedTotal"
      @changeTab="onChangeTab"
    />

    <Screening
      v-if="tableLoading || isShowTaskListTable"
      iconLength="14"
      buttonTextColor="#636B75"
      :showIntegrations="true"
    >
      <template #screening>
        <TextSelectFilter
          v-show="activeTab === TreasuryBalanceVerificationTaskStatusType.PENDING"
          v-model="pendingSortDirection"
          :title="t('common.sort')"
          :options="sortOptions"
          @change="getTableDataList()"
        />
        <TextSelectFilter
          v-show="activeTab === TreasuryBalanceVerificationTaskStatusType.PASSED"
          v-model="passSortDirection"
          :title="t('common.sort')"
          :options="sortOptions"
          @change="getTableDataList()"
        />

        <div @click="onOpenFilterOverlay">
          <div v-if="currentActiveTabFilterCount <= 0" class="elv-treasury-balance-verification-tasks__filter-add">
            <SvgIcon name="add-line" width="14" height="14" />{{ t('common.addFilter') }}
          </div>
          <div v-else class="elv-treasury-balance-verification-tasks__filter-count">
            {{ t('common.filter') }}: {{ currentActiveTabFilterCount }}
          </div>
        </div>
      </template>
    </Screening>
    <VerificationTaskTable
      v-if="tableLoading || isShowTaskListTable"
      :tableLoading="tableLoading"
      :tabType="activeTab"
      :pageParams="pageParams"
      @onResetList="getTableDataList(true)"
      @onChangeSidePage="onChangeSidePage"
      @onChangeSidePageSize="onChangeSidePageSize"
    />
    <div v-else v-loading="tableLoading" class="elv-treasury-balance-verification-tasks-container-empty">
      <i18n-t
        keypath="message.verificationBalanceEmptyTaskTipMessage"
        tag="p"
        scope="global"
        class="elv-treasury-balance-verification-tasks-container-empty-message"
      >
        <template #handlerInfo>
          <span
            :class="{
              'elv-treasury-balance-verification-tasks-refresh-disabled':
                entityStore.entityDetail?.treasuryVerifyStatus === 'DOING'
            }"
            @click="onRefreshVerificationTask"
            >{{ t('button.refreshVerificationTask') }}</span
          >
        </template>
      </i18n-t>
    </div>

    <ElvMessageBox
      ref="refreshTaskMessageBoxRef"
      class="elv-treasury-balance-verification-tasks-message-box"
      :confirmButtonText="t('button.confirm')"
      :cancelButtonText="t('button.cancel')"
      :title="t('button.refreshVerificationTask')"
      :loading="refreshVerificationTaskLoading"
      @onCancelEvent="onChangeRefreshTaskDialogStatus"
      @onConfirmEvent="onConfirmRefreshTask"
    >
      <template #content>
        <div class="elv-treasury-balance-verification-tasks-message-box-content">
          {{ t('message.refreshVerificationTaskTipMessage') }}
        </div>
      </template>
    </ElvMessageBox>
    <VerificationTaskFilterOverlay ref="verificationTaskFilterRef" :tabType="activeTab" @onFilterData="onFilterData" />
    <BalanceVerifyToleranceDialog ref="toleranceSettingRef" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { http } from '@/lib/http'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import timezoneList from '@/config/timezone'
import timezone from 'dayjs/plugin/timezone'
import { cloneDeep, isEmpty } from 'lodash-es'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import balanceVerificationTasksTabs from './components/Tabs.vue'
import TextSelectFilter from '@/components/Base/Filter/TextSelect.vue'
import VerificationTaskTable from './components/VerificationTaskTable.vue'
import { TreasuryBalanceVerificationTaskStatusType } from '#/AccountsTypes'
import Screening from '@/pages/Financials/Project/components/Screening.vue'
import BalanceVerifyToleranceDialog from './components/BalanceVerifyToleranceDialog.vue'
import VerificationTaskFilterOverlay from './components/VerificationTaskFilterOverlay.vue'

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()

const entityStore = useEntityStore()
const accountStore = useAccountStore()
const toleranceSettingRef = useTemplateRef('toleranceSettingRef')
const refreshTaskMessageBoxRef = useTemplateRef('refreshTaskMessageBoxRef')
const verificationTaskFilterRef = useTemplateRef('verificationTaskFilterRef')
const { treasuryBalanceVerifyTaskStat, treasuryBalanceVerifyTaskFilterList } = storeToRefs(accountStore)

const activeTab = ref(TreasuryBalanceVerificationTaskStatusType.PENDING)
const verifying = ref(false)
const tableLoading = ref(false)
const verifyLoading = ref(false)
const refreshVerificationTaskLoading = ref(false)

const passSortDirection = ref('DESC')
const pendingSortDirection = ref('DESC')
const transactionStatus = ref('FINISHED')
const entityTimezone = unref(entityStore.entityDetail?.timezone ?? 'UTC')

const sortOptions = ref([
  {
    value: 'DESC',
    label: t('common.newestToOldest')
  },
  {
    value: 'ASC',
    label: t('common.oldestToNewest')
  },
  {
    value: 'difference',
    label: t('project.differenceHighToLow')
  },
  {
    value: 'differenceFC',
    label: t('project.differenceValueHighToLow')
  }
])

const pageParams = ref({
  page: 1,
  limit: 20
})

const entityId = computed(() => {
  return String(route.params.entityId)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const isShowTaskListTable = computed(() => {
  return entityStore.entityDetail?.treasuryVerifyDatetime !== null
})

const currentActiveTabFilterCount = computed(() => {
  const filterData = treasuryBalanceVerifyTaskFilterList.value.find(
    (item) => item.entityId === entityId.value && item.type === activeTab.value
  )
  return filterData?.total ?? 0
})

const currentEntityTimezone = computed(() => {
  return timezoneList.find((item) => item.area === entityTimezone)?.timezone ?? 'UTC+00:00'
})

/**
 * @description: 获取表格数据
 * @return {*}
 */
const getTableDataList = async (isResetCount = false) => {
  try {
    tableLoading.value = true
    if (isResetCount) {
      accountStore.fetchTreasuryBalanceVerifyTaskStat(entityId.value)
    }
    const params = {
      ...pageParams.value,
      status: activeTab.value
    }
    let data: Record<string, unknown> = {}
    if (activeTab.value === TreasuryBalanceVerificationTaskStatusType.PENDING) {
      data = {}
      if (pendingSortDirection.value === 'difference' || pendingSortDirection.value === 'differenceFC') {
        data.sortField = pendingSortDirection.value
        data.sortDirection = 'DESC'
      } else {
        data.sortField = 'beginDatetime'
        data.sortDirection = pendingSortDirection.value
      }
    } else {
      data = {}
      if (passSortDirection.value === 'difference' || passSortDirection.value === 'differenceFC') {
        data.sortField = passSortDirection.value
        data.sortDirection = 'DESC'
      } else {
        data.sortField = 'beginDatetime'
        data.sortDirection = passSortDirection.value
      }
    }
    const filterData = treasuryBalanceVerifyTaskFilterList.value.find(
      (item) => item.entityId === entityId.value && item.type === activeTab.value
    )
    let filterNewData: any = {}
    if (!isEmpty(filterData?.data)) {
      filterNewData = cloneDeep(filterData.data)
      if (filterNewData?.currency?.length) {
        filterNewData.currency = filterNewData.currency.map((currencyItem: any) => currencyItem.split('__')[1])
      }
      data = { ...data, ...filterNewData }
    }
    accountStore.balanceVerifyTaskListRequestData.push({
      url: `/entity/${entityId.value}/treasuryBalance/verify/task`,
      params,
      data
    })
    const response = await accountStore.fetchTreasuryBalanceVerifyTaskList(entityId.value, params, data)
    accountStore.balanceVerifyTaskListRequestData.length = 0
    return Promise.resolve(response)
  } catch (error: any) {
    if (error?.code !== -200 && error?.message === 'canceled') {
      return Promise.resolve(error)
    }
    ElMessage.error(error.message)
    accountStore.balanceVerifyTaskListRequestData.length = 0
    return Promise.reject(error)
  } finally {
    if (!accountStore.balanceVerifyTaskListRequestData.length) {
      tableLoading.value = false
    }
  }
}

/**
 * @description: 切换页码
 * @param {number} page 页码
 */
const onChangeSidePage = (page: number) => {
  pageParams.value.page = page
  getTableDataList()
}

/**
 * @description: 切换分页条数
 * @param {number} limit 分页大小
 */
const onChangeSidePageSize = (limit: number) => {
  pageParams.value.limit = limit
  pageParams.value.page = 1
  getTableDataList()
}

/**
 * 开始筛选数据
 */

const onFilterData = async () => {
  pageParams.value.page = 1
  await getTableDataList()
}

/**
 * @description: 打开筛选弹窗
 */
const onOpenFilterOverlay = () => {
  verificationTaskFilterRef.value?.onCheckDrawerStatus()
}

/**
 * @description: 清空表格数据
 */
const clearTableData = () => {
  pageParams.value = {
    page: 1,
    limit: 20
  }
  accountStore.treasuryBalanceVerifyTaskList.list = []
}

/**
 * @description: 切换tab
 * @param {Event} e
 */
const onChangeTab = async (e: Event) => {
  const { target } = e
  const tab = (target as HTMLElement)?.getAttribute('aria-controls')
  if (tab) {
    activeTab.value = tab as TreasuryBalanceVerificationTaskStatusType
    if (accountStore.balanceVerifyTaskListRequestData.length) {
      http?.cancelRequest(accountStore.balanceVerifyTaskListRequestData)
      accountStore.balanceVerifyTaskListRequestData = []
    }
    clearTableData()
    getTableDataList()
  }
}

/**
 * 取消刷新余额校验任务
 */
const onChangeRefreshTaskDialogStatus = () => {
  refreshTaskMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 开始执行刷新余额校验任务
 */

const onConfirmRefreshTask = async () => {
  try {
    refreshVerificationTaskLoading.value = true
    await AccountsApi.executeTreasuryBalanceVerify(entityId.value)
    verifying.value = true
    setTimeout(() => {
      entityStore.fetchEntityDetail(entityId.value)
    }, 800)
    onChangeRefreshTaskDialogStatus()
    ElMessage.success(t('message.BalanceVerificationStarted'))
    getTableDataList()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    refreshVerificationTaskLoading.value = false
  }
}

const onOpenToleranceSetting = () => {
  toleranceSettingRef.value?.changeToleranceDialogStatus()
}

/**
 * @description: 余额校验
 */
const onRefreshVerificationTask = async () => {
  if (entityStore.entityDetail?.treasuryVerifyStatus === 'DOING') {
    ElMessage.warning(t('message.verificationTaskInProgressTipMessage'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
    ElMessage.warning(t('message.TransactionInProgress'))
    return
  }
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.balanceVerify &&
    !verifying.value
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  onChangeRefreshTaskDialogStatus()
}

watch(
  () => entityStore.entityDetail,
  async (newValue, oldValue) => {
    if (newValue.entityId !== oldValue?.entityId) {
      accountStore.combineMasterSubAccount = false
      transactionStatus.value = entityStore.entityDetail?.progress.transactionStatus ?? 'FINISHED'
      verifying.value = entityStore.entityDetail?.treasuryVerifyStatus === 'DOING'
    }
    if (
      verifying.value &&
      transactionStatus.value !== 'DOING' &&
      entityStore.entityDetail?.treasuryVerifyStatus === 'DONE'
    ) {
      ElMessage.success(t('message.BalanceVerificationCompleted'))
      if (route.name === 'entity-accounts-balance-verify-tasks') {
        getTableDataList()
        accountStore.fetchTreasuryBalanceVerifyTaskStat(entityId.value)
      }
    }

    verifying.value = entityStore.entityDetail?.treasuryVerifyStatus === 'DOING'
    transactionStatus.value = entityStore.entityDetail?.progress?.transactionStatus ?? 'FINISHED'
  },
  { immediate: true, deep: true }
)

watch(
  () => route,
  async () => {
    if (route.name === 'entity-accounts-balance-verify-tasks') {
      if (route?.query?.type) {
        activeTab.value = route.query.type as TreasuryBalanceVerificationTaskStatusType
      }
      if (route?.query?.page) {
        pageParams.value.page = +route.query.page
      }
      accountStore.fetchTreasuryBalanceVerifyTaskStat(entityId.value)
      accountStore.balanceVerifyTasksFilterDataInit(entityId.value)
      if (entityStore.entityDetail?.treasuryVerifyDatetime !== null) {
        getTableDataList()
      }
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  clearTableData()
  accountStore.treasuryBalanceVerifyTaskList = { filteredCount: 0, list: [] }
  accountStore.balanceVerifyTaskListRequestData = []
  accountStore.treasuryBalanceVerifyTaskStat = {
    passedTotal: 0,
    pendingTotal: 0
  }
})
</script>

<style lang="scss" scoped>
.elv-treasury-balance-verification-tasks-container {
  height: calc(100% - 50px);
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-treasury-balance-verification-tasks-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 17px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .elv-treasury-balance-verification-tasks-header__info {
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';

    h4 {
      color: #0e0f11;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-right: 8px;
    }

    p {
      color: #1e2024;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .elv-treasury-balance-verification-tasks-header__operation {
    display: flex;
    align-items: center;
    gap: 16px;

    .elv-treasury-balance-verification-tasks-header__button {
      height: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;
      white-space: nowrap;

      > div {
        display: flex;
        align-items: center;
      }

      img {
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 4px;
        animation: loading-rotate 2s linear infinite;
      }

      svg {
        fill: #838d95;
        margin-right: 4px;
      }

      &:not(.is-disabled):hover {
        color: #1343bf;

        svg {
          fill: #1343bf;
        }
      }

      &.is-disabled {
        cursor: not-allowed;
      }

      :deep(.el-loading-spinner) {
        position: relative;

        svg {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 12px;
        }
      }
    }

    .elv-treasury-balance-verification-tasks-header__line {
      width: 1px;
      height: 12px;
      background-color: #dde1e6;
    }
  }

  .elv-treasury-balance-verification-tasks__filter-add {
    display: flex;
    align-items: center;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    position: relative;
    padding-left: 16px;
    height: 19px;

    svg {
      fill: #939ba2;
      margin-right: 8px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 14px;
      background: #dde1e6;
    }
  }

  .elv-treasury-balance-verification-tasks__filter-count {
    display: flex;
    padding: 0px 11px;
    height: 19px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    background: #5e85eb;
    color: #fff;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    position: relative;
    margin-left: 16px;

    &::before {
      content: '';
      position: absolute;
      left: -16px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 14px;
      background: #dde1e6;
    }
  }

  .elv-treasury-balance-verification-tasks-container-empty {
    flex: 1;
    min-height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .elv-treasury-balance-verification-tasks-container-empty-message {
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #636b75;
      white-space: pre-wrap;
      text-align: center;

      span {
        cursor: pointer;
        text-decoration: underline;
        font-family: Plus Jakarta Sans;
        color: #1753eb;

        &.elv-treasury-balance-verification-tasks-refresh-disabled {
          color: #838d95;
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.elv-treasury-balance-verification-tasks-message-box {
  .el-dialog__body {
    padding: 30px;

    .elv-treasury-balance-verification-tasks-message-box-content {
      white-space: pre-wrap;
      text-align: center;
    }
  }
}
</style>
