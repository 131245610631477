<template>
  <el-dialog
    v-model="showBackupCodesDialog"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-account-treasury-verification-balance-tolerance-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-treasury-verification-balance-tolerance-header__title">
        {{ t('title.balanceVerifyToleranceTitle') }}
      </h4>
    </template>
    <div class="elv-account-treasury-verification-balance-tolerance-setting-content">
      <div class="tolerance-setting-content-detail">
        <ElvSelect
          v-model="toleranceCheckValue.type"
          width="266px"
          :placeholder="`${t('common.select')}...`"
          :options="toleranceTypeOptions"
          @change="onChangeToleranceValue"
        />
        <span class="tolerance-symbol">≤</span>
        <div class="input-with-currency-container">
          <el-input
            v-model="toleranceCheckValue.value"
            class="input-with-currency"
            style="width: 266px"
            type="number"
            :min="0"
            :placeholder="`${t('common.input')}...`"
            @input="updateCurrencyPosition"
            @blur="onFormatToleranceValue"
          />
          <span
            v-if="toleranceCheckValue.type === 'DIFFERENCE_VALUE'"
            class="input-currency"
            :style="{ left: currencyPosition + 'px' }"
            >{{ entityDetail.defaultCurrency || '' }}</span
          >
        </div>
      </div>
      <p class="tolerance-setting-content-desc">
        <SvgIcon name="warning-emptyBg" width="12" height="12" :fill="'#a8abb2'" />{{
          t('message.BalanceVerifyToleranceTipMessage')
        }}
      </p>
    </div>
    <template #footer>
      <elv-button height="44" width="126" round type="primary" :loading="saveLoading" @click="saveToleranceSetting">{{
        t('button.save')
      }}</elv-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import AccountsApi from '@/api/AccountsApi'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { ElMessage } from 'element-plus'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const saveLoading = ref(false)
const currencyPosition = ref(0)
const showBackupCodesDialog = ref(false)
const toleranceCheckValue = reactive({
  type: '',
  value: 0
})

const toleranceTypeOptions = ref([
  { label: t('project.absoluteDifference'), value: 'DIFFERENCE' },
  { label: t('project.absoluteDifferenceValue'), value: 'DIFFERENCE_VALUE' }
])

const entityId = computed(() => {
  return route.params?.entityId as string
})

/**
 * 检测数字是否为最多小数点后六位，如果超出后六位，去除多余的数字
 * @param {string} numStr - 输入的数字字符串
 * @returns {string} - 处理后的数字字符串
 */
const limitDecimalPlaces = (numStr: string) => {
  const match = numStr.match(/^(\d+(\.\d{0,6})?)\d*$/)
  return match ? match[1] : numStr
}

const updateCurrencyPosition = (value: any) => {
  let currentValue = value
  if (!value) {
    currentValue = 0
  }
  let innerText = currentValue.toString()
  const [integer, decimal] = innerText.split('.')
  innerText = Number(integer.slice(0, 5)) + (decimal?.length ? `.${decimal}` : '')
  if (decimal?.length > 6) {
    innerText = limitDecimalPlaces(innerText)
  }
  toleranceCheckValue.value = innerText
  innerText = toleranceCheckValue.value.toString()
  const textWidth = innerText.length * 9
  currencyPosition.value = textWidth + 22
}

const onFormatToleranceValue = () => {
  toleranceCheckValue.value = Number(toleranceCheckValue.value)
  const innerText = toleranceCheckValue.value.toString()
  const textWidth = innerText.length * 8
  currencyPosition.value = textWidth + 20
}

const changeToleranceDialogStatus = () => {
  showBackupCodesDialog.value = !showBackupCodesDialog.value
  if (showBackupCodesDialog.value) {
    toleranceCheckValue.type = entityDetail.value?.treasuryVerifyTolerance.type || 'DIFFERENCE'
    toleranceCheckValue.value = entityDetail.value?.treasuryVerifyTolerance.value || 0
    updateCurrencyPosition(toleranceCheckValue.value)
  }
}

const onChangeToleranceValue = () => {
  toleranceCheckValue.value = 0
  updateCurrencyPosition(toleranceCheckValue.value)
}

const saveToleranceSetting = async () => {
  saveLoading.value = true
  try {
    await AccountsApi.setTreasuryBalanceVerifyTolerance(entityId.value, {
      treasuryVerifyTolerance: {
        type: toleranceCheckValue.type,
        value: toleranceCheckValue.value
      }
    })
    await entityStore.fetchEntityDetail(entityId.value)
    changeToleranceDialogStatus()
    ElMessage.success(t('message.saveSuccess'))
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

defineExpose({
  changeToleranceDialogStatus
})
</script>

<style lang="scss">
.elv-account-treasury-verification-balance-tolerance-dialog {
  width: 620px;
  height: 229px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-treasury-verification-balance-tolerance-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-integration-account-mappings-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 19px;

      p {
        width: 310px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
}

.elv-account-treasury-verification-balance-tolerance-setting-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .tolerance-setting-content-detail {
    display: flex;
    align-items: center;
    gap: 16px;

    .input-with-currency-container {
      position: relative;
      display: inline-block;

      .input-with-currency {
        input::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }

        .el-input__wrapper {
          padding-right: 40px;
        }
      }

      .input-currency {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none; /* 使 USD 文本不可点击 */
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        color: #aaafb6;
      }
    }
  }

  .tolerance-setting-content-desc {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #838d95;

    svg {
      margin-right: 4px;
    }
  }
}
</style>
