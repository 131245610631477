<template>
  <div class="elv-treasury-balance-verification-tasks-tabBar" @click="onChangeTab($event)">
    <div
      class="elv-treasury-balance-verification-tasks-tabBar-pane"
      :class="{ 'is-active': activeTab === TreasuryBalanceVerificationTaskStatusType.PENDING }"
      :aria-controls="TreasuryBalanceVerificationTaskStatusType.PENDING"
    >
      {{ t('reconciliation.pending') }} ({{ formatNumber(props.pendingTotal) }})
    </div>
    <div
      class="elv-treasury-balance-verification-tasks-tabBar-pane"
      :class="{ 'is-active': activeTab === TreasuryBalanceVerificationTaskStatusType.PASSED }"
      :aria-controls="TreasuryBalanceVerificationTaskStatusType.PASSED"
    >
      {{ t('common.passed') }} ({{ formatNumber(props.passedTotal) }})
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatNumber } from '@/lib/utils'
import { TreasuryBalanceVerificationTaskStatusType } from '#/AccountsTypes'

const props = defineProps({
  pendingTotal: {
    type: Number,
    default: 0
  },
  passedTotal: {
    type: Number,
    default: 0
  }
})

const { t } = useI18n()

const activeTab = defineModel<string>('activeTab', { required: true })
const emit = defineEmits(['changeTab'])

const onChangeTab = (e: any) => {
  emit('changeTab', e)
}
</script>

<style lang="scss" scoped>
.elv-treasury-balance-verification-tasks-tabBar {
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  margin-left: 17px;
  margin-top: 16px;

  .elv-treasury-balance-verification-tasks-tabBar-pane {
    display: flex;
    height: 27px;
    padding: 0px 16px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid #e5edff;
    background: #fff;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: all 0.1s;
    white-space: nowrap;

    &:not(.is-active):hover {
      border: 1px solid #5e85eb;
    }

    &.is-active {
      background: #1753eb;
      color: #fff;
      border: 1px solid #1753eb;
    }
  }
}
</style>
